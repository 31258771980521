import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage"
import ImageResize from "@taoqf/quill-image-resize-module/image-resize.min";
import Quill from 'quill/quill';

export default class extends Controller {
  static get values() {
    return {
      placeholder: String,
    }
  }

  connect() {
    Quill.register('modules/imageResize', ImageResize);
    this.#initializeTextEditor();
  }

  #initializeTextEditor() {
    const quill = new Quill('#editor-container', {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          [{ size: [] }],
          [
            'bold', 'italic', 'underline', 'strike',
            { 'script': 'super' },
            { 'script': 'sub' },
            'code', 'link'
          ],
          ['blockquote', 'code-block', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: ['center', 'right', 'justify', false] }],
          [{ indent: '-1' }, { indent: '+1' }]

        ],
        imageResize: {
          displaySize: true,
          displayStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white'
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar']
        }
      },
      theme: 'snow',
      placeholder: this.placeholderValue
    });

    this.#addSubmitCallback(quill);
    this.#addImageHandler(quill);
  }

  #addSubmitCallback(textEditor) {
    document.querySelector('form').onsubmit = function () {
      const body = document.querySelector('input[class="article-content"]');
      body.value = textEditor.root.innerHTML
    };
  }

  #addImageHandler(textEditor) {
    textEditor.getModule('toolbar').addHandler('image', () => {
      this.#importImage(textEditor);
    });
  }

  #importImage(textEditor) {
    const MAX_FILE_SIZE = 1000000
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files[0];

      if (file.size > MAX_FILE_SIZE) {
        alert("Only support attachment files upto size 1MB!")
        return
      }

      if (/^image\//.test(file.type)) {
        this.#uploadImage(textEditor, file);
      } else {
        alert('Only images allowed');
      }
    };
  };

  #uploadImage(textEditor, file) {
    const formData = new FormData();
    formData.append('blob', file);

    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        this.#insertImage(
          textEditor,
          `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
        );
      }
    });
  };

  #insertImage(textEditor, fileUrl) {
    const range = textEditor.getSelection();
    textEditor.insertEmbed(range.index, 'image', fileUrl);
  };

}
