import { Controller } from "@hotwired/stimulus";
import Typed from 'typed.js';

export default class extends Controller {
  static get values() {
    return  {
      id: String,
      strings: Array,
    }
  }

  connect(){
    const typed = new Typed(`#${this.idValue}`, {
      strings: this.stringsValue,
      typeSpeed: 100,
      backDelay: 5000,
      loop: true,
    });
  }
}
