import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect(){
    this.article = document.querySelector('article');

    if (this.article) this.#monitorArticleScrolling();
  }

  #monitorArticleScrolling() {
    this.#updateProgressBarAdvancement();
    document.addEventListener('scroll', this.#updateProgressBarAdvancement.bind(this))
  }

  #updateProgressBarAdvancement() {
    let articleRect = this.article.getBoundingClientRect()

    if (articleRect.top < 0 && articleRect.top > - articleRect.height) {
      let advancementRatio = (- articleRect.top / articleRect.height).toFixed(3)
      this.element.style.width = `${advancementRatio * 100}%`
      this.element.style.height = '6px'
    } else if (articleRect.top > 0) {
      this.element.style.width = '0%'
      this.element.style.height = '0px'
    } else {
      this.element.style.width = '100%'
    }
  }
}
