import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["dropdownMenu", "dropdownButton"];
  }

  toggle() {
    this.dropdownMenuTarget.classList.toggle("max-h-0");
    this.dropdownMenuTarget.classList.toggle("max-h-screen");
  }

  hide(event) {
    if (event && this.dropdownButtonTarget.contains(event.target)) {
      return;
    }
    this.dropdownMenuTarget.classList.add("max-h-0");
    this.dropdownMenuTarget.classList.remove("max-h-screen");
  }
}
