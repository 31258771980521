import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["flash"];
  }

  connect(){
    setTimeout(
      () => this.flashTarget.classList.add("hidden"),
      3000
    )
  }

}
